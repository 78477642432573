.scanQr {
    position: relative;
    width: 140px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
}

.scanAnimated {
    width: 100%;
    height: 10px;
    position: absolute;
    animation-name: zigzag;
    animation-duration: 4s;
    animation-iteration-count: infinite;
}

@keyframes zigzag {
    from {
        background: linear-gradient(
            0deg,
            #ff5b23 0%,
            rgba(255, 167, 137, 0.536896) 24.43%,
            rgba(255, 255, 255, 0) 100%
        );
        top: 0;
    }

    50% {
        background: linear-gradient(
            0deg,
            #ff5b23 0%,
            rgba(255, 167, 137, 0.536896) 24.43%,
            rgba(255, 255, 255, 0) 100%
        );
        top: 95%;
    }

    51% {
        background: linear-gradient(
            180deg,
            #ff5b23 0%,
            rgba(255, 167, 137, 0.536896) 24.43%,
            rgba(255, 255, 255, 0) 90%
        );
        top: 95%;
    }

    100% {
        background: linear-gradient(
            180deg,
            #ff5b23 0%,
            rgba(255, 167, 137, 0.536896) 24.43%,
            rgba(255, 255, 255, 0) 100%
        );
        top: 0;
    }
}

@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);