.root {
    display: flex;
    align-items: center;
    flex-direction: row;
    @media screen and (max-width: 576px) {
        flex-direction: column;
        align-items: start;
    }
    :global {
        .ant-typography {
            color: var(--bg-color-mono-2);
            font-weight: var(--font-small-body-link-weight);
        }
        span {
            color: var(--color-body);
            font-weight: var(--font-small-body-semibold-weight);
            padding-left: 8px;
        }
    }
}
.divider {
    margin: 0 24px;
    @media screen and (max-width: 576px) {
        display: none;
    }
}

.itemsValid {
    margin: 8px 0;
}

.amountValid {
}

@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);