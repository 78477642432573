.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-lighter {
    font-weight: lighter !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-medium {
    font-weight: 500 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.text-primary {
    color: var(--color-light-orange) !important;
}

.text-title {
    font-size: var(--font-title-1-size);
    font-weight: 500;
    color: var(--color-body);
}

.text-sub-title {
    font-weight: var(--font-small-body-regular-weight);
    color: var(--color-body);
}

.text-black {
    color: var(--color-body) !important;
}
