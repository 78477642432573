.root {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    padding: 0;
    width: 100%;
    box-shadow: 0px 1px 4px var(--bg-color-mono-4);
    background: var(--bg-color-mono-7);
    margin: 0 auto;
    min-height: 72px;
}

.main {
    max-width: 786px;
    margin: 0 auto;
    font-size: var(--font-small-body-regular-size);
    height: 72px;
    color: var(--color-body);
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.breadcrumbItem {
    font-size: var(--font-body-medium-size);
    padding-top: 5px;
    display: inline-block;
}

@media screen and(max-width: 1024px) {
    .headerMain {
        padding: 0 10px 0 10px;
    }
}

@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);