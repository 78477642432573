@import '~/node_modules/antd/dist/antd.less';
:root {
    /*background-color*/
    --bg-color-mono-1: #000;
    --bg-color-mono-2: #5e5c62;
    --bg-color-mono-3: #aaaaaa;
    --bg-color-mono-4: #e4e4e4;
    --bg-color-mono-5: #fafafa;
    --bg-color-mono-6: #ededed;
    --bg-color-mono-7: #fff;
    --bg-color-mono-8: #d7d7d7;
    --bg-linear-gradient: linear-gradient(94.64deg, #f39423 3.75%, #ee511d 95.77%);
    /*font-color*/
    --color-body: #000000;
    --color-blue: #0066b3;
    --color-white: #fff;
    --color-dard-orange: #f47920;
    --color-orange: #f7941f;
    --color-light-orange: #fdb814;
    --color-text-error: #f42020;
    /*font style*/
    --font-family: 'Inter', sans-serif;
    --font-large-title-size: 30px;
    --font-large-title-weight: 600;
    --font-large-title-height: 40px;
    --font-title-1-size: 26px;
    --font-title-1-weight: 600;
    --font-title-1-height: 34px;
    --font-title-2-size: 24px;
    --font-title-2-weight: 600;
    --font-title-2-height: 28px;
    --font-title-3-size: 20px;
    --font-title-3-weight: 600;
    --font-title-3-height: 24px;
    --font-body-bold-size: 16px;
    --font-body-bold-weight: 700;
    --font-body-bold-height: 160%;
    --font-body-semibold-size: 16px;
    --font-body-semibold-weight: 600;
    --font-body-semibold-height: 22px;
    --font-body-medium-size: 16px;
    --font-body-medium-weight: 500;
    --font-body-medium-height: 160%;
    --font-body-regular-size: 16px;
    --font-body-regular-weight: 400;
    --font-body-regular-height: 160%;
    --font-body-regular-link-size: 16px;
    --font-body-regular-link-weight: 400;
    --font-body-regular-link-height: 160%;
    --font-small-body-bold-size: 14px;
    --font-small-body-bold-weight: 700;
    --font-small-body-bold-height: 18px;
    --font-small-body-medium-size: 14px;
    --font-small-body-medium-weight: 500;
    --font-small-body-medium-height: 18px;
    --font-small-body-semibold-size: 14px;
    --font-small-body-semibold-weight: 600;
    --font-small-body-semibold-height: 18px;
    --font-small-body-regular-size: 14px;
    --font-small-body-regular-weight: 400;
    --font-small-body-regular-height: 18px;
    --font-small-body-link-size: 14px;
    --font-small-body-link-weight: 400;
    --font-small-body-link-height: 18px;
    --font-super-small-body-regular-size: 12px;
    --font-super-small-body-regular-weight: 400;
    --font-super-small-body-regular-height: 18px;
    --padding-section-desktop: 40px;
    --padding-section-sub-desktop: 20px;
    --padding-section-mobile: 20px;
    --padding-section-sub-mobile: 10px;
    --padding-section-large-mobile: 40px;
    --padding-section-sub-large-mobile: 20px;
    --margin-section-desktop: 40px;
    --margin-section-sub-desktop: 20px;
    --margin-section-mobile: 20px;
    --margin-section-sub-mobile: 10px;
    --margin-section-large-mobile: 40px;
    --margin-section-sub-large-mobile: 20px;
    --drop-shadown: 0px 1px 5px rgba(188, 188, 188, 0.25);
    --drop-shadown-tablet: 0px 1px 4px rgba(113, 113, 113, 0.25);
    --inner-shadown: inset 0px -1px 0px #e4e4e4, inset 0px 1px 0px #e4e4e4, inset 1px 0px 0px #e4e4e4,
        inset -1px 0px 0px #e4e4e4;
    --header-heigth: 77px;
    --header-heigth-tablet: 70px;
    --header-scroll-height: 60px;
}
// @font-size-title: 26px;
// @font-size-title-medium: 20px;
// @font-size-small: 14px;
// @font-size-medium: var(--font-body-medium-size); // 16px
// @primary-color: #fdb814;
// @primary-color-dark: #f47920;
// @font-weight-regular: 400;
// @font-weight-medium: 500;
// @font-weight-semibold: 600;
// @font-weight-bold: 700;
// @font-weight-normal: 400;
// @color-black: var(--color-body);
// @color-white: #fff;

// // background
// @bg-mono-2: var(--bg-color-mono-2);
// @bg-mono-3: var(--bg-color-mono-3);
// @bg-mono-4: var(--bg-color-mono-4);
// @bg-error: #ff4d4f;
