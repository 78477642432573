.ant-radio-inner {
    border-color: var(--bg-color-mono-2) !important;
    width: 18px !important;
    height: 18px !important;
}
input[type='radio']:checked ~ .ant-radio-inner::after {
    width: 5px !important;
    height: 5px !important;
    background-color: transparent !important;
}

input[type='radio']:checked ~ .ant-radio-inner {
    border: 7px solid var(--bg-color-mono-1) !important;
}

.ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none !important;
}

.ant-radio-wrapper {
    font-size: var(--font-small-body-semibold-size) !important ;
    color: var(--color-body) !important;
}
.ant-radio {
    top: 0.3rem !important;
}
