.title {
    color: var(--color-body);
    font-size: var(--font-title-3-size);
    font-weight: var(--font-body-semibold-weight);
    margin-top: 40px;
}
.subTitle {
    margin-top: 20px;
    font-size: var(--font-body-medium-size);
    color: #4d5562;
}

.expiredOtpIn {
    margin-bottom: 5px;
    font-size: var(--font-body-medium-size);
}

.buttonVerify {
    display: flex;
    justify-content: flex-end;
    padding: 20px 14px;
}

.textError {
    color: var(--color-text-error) !important;
    font-weight: var(--font-body-regular-weight);
}

@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);