.root {
    :global {
        .ant-btn {
            border: none;
            box-shadow: none;
            text-shadow: none;

            height: auto;
            padding: 11px 32px;
            border-radius: 5px;
        }
        .ant-btn-primary {
            background-color: var(--color-light-orange);
            transition: all 0.3s ease;
            span {
                font-size: var(--font-body-medium-size);
                font-weight: var(--font-body-semibold-weight);
                color: var(--color-body);
                transition: all 0.3s ease;
            }
            &:hover {
                background-color: var(--color-body);
                span {
                    color: var(--bg-color-mono-7);
                }
            }
        }
        .ant-btn-text {
            background: transparent;
            span {
                font-size: var(--font-body-medium-size);
                font-weight: var(--font-small-body-regular-weight);
                text-decoration: underline;
                transition: all 0.3s ease;
            }

            &:hover {
                background: transparent;
                span {
                    color: var(--color-dard-orange);
                }
            }
        }
        .ant-btn-text:hover,
        .ant-btn-text:focus {
            background: transparent;
        }
        .ant-btn:focus {
            box-shadow: none;
        }
        .ant-btn:active {
            border-color: transparent;
        }

        button:disabled {
            background-color: var(--bg-color-mono-4);
            &:hover {
                background-color: var(--bg-color-mono-4);
                span {
                    color: var(--bg-color-mono-3);
                }
            }

            span {
                color: var(--bg-color-mono-3);
                font-weight: var(--font-body-semibold-weight);
            }
        }
    }
}

@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);