.footer {
    height: 72px;

    background-color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    color: var(--color-body);

    margin-top: 44px;

    /* .ant-layout-content {
      display: flex;
      padding: 0 10px 0 10px;
      width: 100%;
      height: 100%;
      max-width: 1110px;
    }

    &__main {
      border-top: 1px solid #e4e4e4;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;
      // gap: 10px;
    } */
}

.main {
    border-top: 1px solid #e4e4e4;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content {
    display: flex;
    padding: 0 10px 0 10px;
    width: 100%;
    height: 100%;
    max-width: 1110px;
}

.currentLang {
    display: flex;
    align-items: center;
    justify-content: center;
}

.copyRight {
    font-size: var(--font-small-body-bold-size);
}

.locales {
    min-width: 120px;
}

.textLocales {
    font-size: var(--font-small-body-medium-size);
    cursor: pointer;
}

@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);