#root {
    height: 100%;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
    font-weight: var(--font-small-body-regular-weight);
    font-size: var(--font-body-regular-size) !important;
    color: var(--color-body);
    font-family: var(--font-family);
}

html {
    scroll-behavior: smooth;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.truncate--2 {
    -webkit-line-clamp: 2;
    word-break: break-all;
}

.truncate--3 {
    -webkit-line-clamp: 3;
    word-break: break-all;
}

::-webkit-scrollbar {
    width: 5px;
    padding: 0px;
}

::-webkit-scrollbar-thumb {
    min-height: 50px;
    background-color: rgb(199, 199, 199);
    height: 50px;
    border-radius: 20px;
}

::-webkit-scrollbar-track {
    background: #e3e3e3;
}

.ant-modal-body {
    padding: 16px 24px 32px 24px !important;
}
.ant-modal-content .ant-modal-close {
    right: 5px !important;
}
.ant-modal-content {
    .ant-modal-close-x {
        display: block;
        font-size: 16px;
        font-style: normal;
        height: 54px !important;
        line-height: 54px;
        text-align: center;
        text-rendering: auto;
        text-transform: none;
        width: 54px;
    }
}

.ant-table-filter-dropdown {
    background: #fff;
    border-radius: 10px !important;
    box-shadow: 0 1px 10px rgb(0 0 0 / 15%) !important;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
    display: none !important;
    animation: none !important;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none !important;
    animation: none !important;
}
