.root {
    // background: #ffffff;
    // box-shadow: 0px 1px 5px rgba(188, 188, 188, 0.5);
    // :global {
    //     .ant-tabs {
    //         // background-color: red !important;
    //     }
    // }
}

.tabs {
    :global {
        .ant-tabs-nav {
            margin: 0;
        }

        .ant-tabs-tab {
            font-size: 14px !important;
            height: 55px;
        }

        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: var(--color-body);
            font-weight: var(--font-body-semibold-weight);
            text-shadow: none;
        }

        .ant-tabs-nav-list {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        .ant-tabs-tab-btn:focus,
        .ant-tabs-tab-remove:focus,
        .ant-tabs-tab-btn:active,
        .ant-tabs-tab-remove:active {
            color: var(--color-body);
        }

        .ant-tabs-ink-bar {
            height: 3px !important;
        }

        .ant-tabs-ink-bar::after {
            content: '';

            position: absolute;
            left: 50%;
            right: 0;
            bottom: 0;

            height: inherit;
            width: 100%;

            background: var(--bg-linear-gradient) !important;

            transform: translateX(-50%);
        }

        .ant-tabs-tab:hover {
            color: var(--color-body);
        }
        .ant-tabs-tab.ant-tabs-tab-disabled {
            color: var(--color-body);
        }
        .ant-tabs-nav-list {
            @media screen and(max-width: 576px) {
                padding: 0 10px;
            }
        }
    }
}

@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);