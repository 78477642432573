.root {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-top: var(--font-large-title-size);

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 4px;
    border: 1px dashed var(--bg-color-mono-3);
    background-color: #e4e4e457;
    height: 268px;
    :global {
        .ant-upload-wrapper,
        .ant-upload {
            width: 100%;
            height: 100%;
            .ant-upload {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
}
.processing {
    position: absolute;
    top: 0;
    left: 0;
    width: 120px;
    height: 6px;
    border-radius: 4px;
    background: var(--bg-linear-gradient);
    animation: fileProcessing 2s forwards;
}

.content {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.title {
    font-size: var(--font-small-body-link-size);
    font-weight: var(--font-small-body-link-weight);
    margin-top: 15px;
    margin-bottom: 10px;
}

.subTitle {
    color: var(-bg-color-mono-2);
    font-size: var(--font-small-body-link-size);
    font-weight: var(--font-small-body-link-weight);
}

.upload {
    width: 100%;
    height: 100%;
    :global {
        .ant-upload-list-text {
            display: none;
        }
    }
}

.uploading {
    padding-top: 10px;
}

@keyframes fileProcessing {
    from {
        width: 0%;
    }
    to {
        width: 98%;
    }
}

@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);