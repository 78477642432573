.root {
    :global {
        .ant-pagination-simple-pager input {
            padding: 0px;
            width: 35px;
            height: 35px;
        }

        .ant-pagination-simple-pager {
            height: 30px;
            margin-right: 0px;
        }

        .ant-pagination {
            display: flex;
            align-items: center;
        }
        .ant-pagination-prev {
            margin-right: 0;
        }
        .ant-pagination-disabled {
            color: var(--bg-color-mono-3);
        }
        .ant-pagination-item {
            border: none;
            margin-right: 0;
            font-size: 15px;
        }
        .ant-pagination-item-link {
            border: none;
        }
        .ant-pagination-disabled {
            button[type='button']:disabled {
                background-color: transparent;
            }
        }
        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
            border: none;
            border-width: 0;
        }
    }
}

@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);