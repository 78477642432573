.textOr {
    font-size: var(--font-small-body-regular-size);
    font-weight: var(--font-small-body-semibold-weight);
    margin-bottom: 30px;
}

.appItem {
    text-align: center;
}

.appStep {
    position: relative;

    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    color: white;
    font-weight: 700;
    background: var(--bg-linear-gradient);
    margin: 0 auto;
}

.stepDescription {
    font-size: var(--font-small-body-semibold-size);
    font-weight: var(--font-small-body-semibold-weight);
    margin: 10px 0;
}

.image {
    text-align: center;
    padding-top: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 370px;
    margin-top: 20px;

    @media screen and(min-width: 992px) {
        height: 478px;
    }

    @media screen and(min-width: 1110px) {
        height: 520px;
    }

    @media screen and(min-width: 1200px) {
        height: 370px;
    }

}

.stepLine {
    display: none;
    position: absolute;
    left: 45px;
    top: 50%;
    transform: translateY(-50%);
    width: 185px;
    border: 1px dashed #aaaaaa;

    @media screen and(min-width: 992px) {
        display: block;
        width: 300px;
    }

    @media screen and(min-width: 1110px) {
        display: block;
        width: 335px;
    }

    @media screen and(min-width: 1200px) {
        display: block;
        width: 220px;
    }
}
@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);