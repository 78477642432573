.content {
    padding: var(--padding-section-sub-desktop) var(--padding-section-sub-desktop) 0px
        var(--padding-section-sub-desktop);
}
.divider {
    margin: 0;
}
.actions {
    display: flex;
    padding: 0 20px 20px;
    :global {
        .ant-btn,
        .ant-btn:active,
        .ant-btn:focus {
            outline: none !important;
            box-shadow: none !important;
            border: 1px solid transparent;
            border-color: transparent !important;
        }

        .ant-btn {
            animation: none;
        }

        .ant-btn-default.show {
            visibility: visible;
        }

        .ant-btn-default.hidden {
            visibility: hidden;
        }
    }
}

.btnApply {
    padding: 5.5px 21.5px !important;
    :global {
        span {
            font-size: 14px !important;
        }
    }
}

.btnTextShow {
    width: 94px;
    visibility: visible;
    padding: 5.5px 21.5px !important;
    :global {
        span {
            font-size: 14px !important;
        }
    }
    &:hover {
        span {
            color: var(--color-body) !important;
        }
    }
}

.btnTextHidden {
    opacity: 0;
}

@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);