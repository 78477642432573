.root {
    :global {
    }
}

.modalContent {
    display: flex;
    padding-top: 33px;
}

.title {
    font-size: 20px;
    font-weight: var(--font-body-semibold-weight);
    margin-bottom: 16px;
    padding-left: 16px;
    min-height: 32px;
}

.prompt {
    font-size: var(--font-small-body-semibold-size);
    padding-left: 16px;
}

@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);