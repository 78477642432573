.root {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;

    :global {
        .ant-input:hover,
        .ant-input:focus {
            border-width: 2px;
            border-top: 0 solid transparent;
            border-left: 0 solid transparent;
            border-right: 0 solid transparent;
            border-bottom: 2px solid var(--bg-color-mono-3);
            .ant-input-focused {
                box-shadow: none;
            }
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type='number'] {
            -moz-appearance: textfield;
        }
    }
}

.otp {
    font-size: 20px !important;
    font-weight: 700;
    text-align: center !important;

    width: 50px !important;
    height: 50px !important;

    border-radius: 0 !important;
    box-shadow: none !important;
    border-top: 0 solid transparent !important;
    border-left: 0 solid transparent !important;
    border-right: 0 solid transparent !important;
    border-bottom: 2px solid var(--bg-color-mono-3) !important;
    &:focus,
    .ant-input-focused {
        box-shadow: none !important;
    }
}

@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);