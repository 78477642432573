.content {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
}

.title {
    font-size: var(--font-title-3-size);
    font-weight: var(--font-body-semibold-weight);
    margin-top: 16px;
}

.subTitle {
    margin-top: 8px;
    font-size: var(--font-small-body-semibold-size);
    color: #4d5562;
}

.form {
    margin-top: 20px;
}

.notice {
    margin-top: 20px;
    font-size: var(--font-small-body-semibold-size);
    font-weight: 700;
}

.promptNotice {
    font-size: var(--font-small-body-semibold-size);
    margin-top: 10px;
    font-weight: var(--font-small-body-regular-weight);
}

.footerModal {
    padding: 16px 8px;
    display: flex;
    justify-content: flex-end;
}

@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);