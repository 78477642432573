.root {
    position: relative;
    transition: all 0.3s;
    margin-top: 20px;
    :global {
        .ant-input {
            border-bottom: 1px solid #aaaaaa;
            border-radius: 0;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            padding: 0;
        }
        .ant-input:hover,
        .ant-input:focus,
        .ant-input-focused {
            border: 0;
            box-shadow: none;
            border-bottom: 1px solid var(--color-body);
        }

        .ant-input-status-error {
            border-width: 1.5px;
            border-bottom: 1.5px solid var(--bg-color-mono-8);
            transition: all 0.1s;
        }

        .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
        .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
            border-color: none;
            box-shadow: none;
            border-right-width: 0;
            outline: none;
            border-width: 1.5px;
            border-bottom: 1.5px solid #ff4d4f;
            transition: all 0.1s;
        }
        .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
        .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
            box-shadow: none;
        }
    }
}

.floatingInput {
    border: none;
    width: 100%;
    min-height: 42px;
    display: block;
    border-radius: 0;
    padding: 17px 0 0;
    color: var(--color-body);
    background-color: transparent;
    font-size: var(--font-body-medium-size) !important;
    border-bottom: 1px solid var(--bg-color-mono-3);
}

.label {
    left: 0;
    top: 5px;
    position: absolute;
    font-weight: normal;
    pointer-events: none;
    transition: 0.3s ease all;
    font-size: var(--font-body-medium-size) !important;
    color: var(--bg-color-mono-2);
}

.asLabel {
    top: 2px;
    left: 2px;
    padding: 0;
    font-size: var(--font-small-body-semibold-size) !important;
    position: absolute;
    pointer-events: none;
    transition: 0.3s ease all;
    color: var(--bg-color-mono-2);
    transform: translateY(-75%) translateX(-2px);
    -moz-transition: 0.3s ease all;
    -webkit-transition: 0.3s ease all;
}

.asPlaceholder {
    color: var(--bg-color-mono-2);
    top: 10px;
}

.loading {
    position: absolute;
    top: 50%;
    right: 0;
    svg {
        fill: var(--color-orange);
    }
}

.eyeIcon {
    position: absolute;
    right: 0;
    top: 5px;

    padding-top: 17px;
    cursor: pointer;
}

@primary-color: #F7941D;@link-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #DD1616;@font-size-base: 14px;@font-weight: 400;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: #5E5C62;@disabled-color: #AAAAAA;@border-radius-base: 5px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);